import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Columns, Icon } from "react-bulma-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faPhone, faClock, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { YMaps, Map, Placemark } from "react-yandex-maps"
import Obfuscate from "react-obfuscate"

import Layout from "../../components/Layout"


const Contacts = () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        address {
                            city, street, house, block, room, latitude, longitude
                        },
                        phones {
                            type, value
                        },
                        openHours {
                            weekDays, dayTimes, caption
                        },
                        emails {
                            type, value, caption
                        }
                    }
                }
            }
        `
    )
    const { title, address, phones, openHours, emails } = data.site.siteMetadata
    const { city, street, house, block, room } = address
    return (
        <Layout
            title="Контакты"
            subtitle="На этой странице Вы найдете схему проезда в наш офис и наши контакты"
            slug="/main/contacts/"
        >
            <Columns>
                <Columns.Column>
                        <address className="has-text-weight-bold">
                            <Icon size="medium">
                                <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="has-text-danger"
                                />
                            </Icon>
                            <span>
                                {city}, {street}, {house}, {block}, {room}
                            </span>
                        </address>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <ul>
                        {phones.map((phone, index) =>
                            <li
                                className={index === 0 ? "has-text-weight-bold" : ""}
                                key={index}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        className="has-text-info"
                                    />
                                </Icon>
                                <span>
                                  {phone.type}: <a href={'tel:'.concat(phone.value.replace(/[^\d+]/g, ''))}>{phone.value}</a>
                                </span>
                            </li>
                        )}
                    </ul>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <span className="has-text-weight-bold">Режим работы:</span>
                    <ul>
                        {openHours.map((item, index) =>
                            <li key={index}>
                                <Icon size="medium">
                                    <FontAwesomeIcon
                                        icon={faClock}
                                        className="has-text-success"
                                    />
                                </Icon>
                                <span>
                                {item.weekDays}:
                                {" "}
                                {item.dayTimes}
                                {" "}
                                {item.caption ?
                                    <span className="is-italic">
                                        ({item.caption})
                                    </span> :
                                    ""}
                                </span>
                            </li>
                        )}
                    </ul>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <ul>
                        {emails.map((item, index) =>
                            <li
                                key={index}
                                className={index === 0 ? "has-text-weight-bold" : ""}
                            >
                                <Icon size="medium">
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="has-text-warning"
                                    />
                                </Icon>
                                <span>
                                    {item.type}:
                                    {" "}
                                    <Obfuscate email={item.value} />
                                    {" "}
                                    {item.caption ?
                                        <span className="is-italic">
                                            ({item.caption})
                                        </span> :
                                        ""}
                                </span>
                            </li>
                        )}
                    </ul>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column className="is-offset-1 is-10">
                    <figure className="image is-4by3">
                        <YMaps>
                            <Map
                                className="has-ratio"
                                width={"100%"}
                                height={"100%"}
                                defaultState={{
                                    center: [address.latitude, address.longitude],
                                    zoom: 16,
                                    controls: ['zoomControl', 'fullscreenControl'],
                                }}
                                modules={['control.ZoomControl', 'control.FullscreenControl']}
                            >
                                <Placemark
                                    modules={['geoObject.addon.balloon']}
                                    defaultGeometry={[address.latitude, address.longitude]}
                                    properties={{
                                        iconContent: title,
                                        balloonContentBody: `${city}, ${street}, ${house}, ${block}, ${room}`
                                    }}
                                    options={{ preset: "islands#redStretchyIcon" }}
                                />
                            </Map>
                        </YMaps>
                    </figure>
                </Columns.Column>
            </Columns>
        </Layout>
    )
}

export default Contacts
